import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';

import { map, Observable } from 'rxjs';
import { JsonArray, JsonObject } from 'type-fest';

import {
  AccountCheckVerificationCodeReqPayload,
  AccountCloseReqPayload,
  AccountCreateContactReqPayload,
  AccountCreateFeedbackReqPayload,
  AccountCustomerByEmailModel,
  AccountFeedbackResPayload,
  AccountFlag,
  AccountFollowerModel,
  AccountFollowReqPayload,
  AccountFormDataModel,
  AccountModel,
  AccountNewCustomerModel,
  AccountNotificationModel,
  AccountNotificationReqPayload,
  AccountPreference,
  AccountRecoverPasswordReqPayload,
  AccountRequirement,
  AccountRoleEnum,
  AccountStatus,
  AccountTokeListModel,
  AccountToken,
  AccountUpdatePasswordReqPayload,
  AccountUpdateReqPayload,
  AccountVerifyEmailModel,
  AccountVerifyModel,
  FollowedAccountModel,
  IAccountFollowerResModel,
  IAccountPermission,
  IAccountVerify,
  INewCustomerByEmail,
  OpsAccountCreateReqPayload,
  OpsAccountModel,
  VerificationOptionEnum
} from '../models';

import {
  AbstractErosApiConfig,
  throwErrorIfErosApiConfigIsMissing
} from '@eros-api/core';
import { Currency } from '@eros-api/models/commerce';
import { FileDownloadService } from '@eros-api/services/file-download.service';
import { FileType } from '@eros-api/models/export-file.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private readonly basePath: string;

  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig,
    private fileDownloadService: FileDownloadService,
    private http: HttpClient
  ) {
    throwErrorIfErosApiConfigIsMissing(config);

    this.basePath = `${config.getApiUrl()}/account`;
  }

  createAccessToken(
    username: string,
    password: string
  ): Observable<AccountToken> {
    const url = `${this.basePath}/token`;
    const body = { username, password };

    return this.http
      .post<AccountToken>(url, body)
      .pipe(map((json) => AccountToken.fromJson(json)));
  }

  getById(id: number): Observable<AccountModel> {
    const url = `${this.basePath}/${id}`;

    return this.http.get(url).pipe(map((json) => AccountModel.fromJSON(json)));
  }

  findAccounts(ids: number[]): Observable<AccountModel[]> {
    const url = `${this.basePath}/_bulk`;

    return this.http
      .post<JsonArray>(url, { ids })
      .pipe(map((json) => json.map((rec) => AccountModel.fromJSON(rec))));
  }

  getList(): Observable<AccountModel[]> {
    const url = `${this.basePath}`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(map((json) => json.map((rec) => AccountModel.fromJSON(rec))));
  }

  delete(accountId: number): Observable<{ removed: number }> {
    const url = `${this.basePath}/${accountId}`;

    return this.http.delete<{ removed: number }>(url, {});
  }

  reset2faAppToEmail(
    accountId: number,
    body: {
      tfa_type: VerificationOptionEnum;
    }
  ): Observable<unknown> {
    const url = `${this.basePath}/token/2fa-reset/${accountId}`;

    return this.http.patch<unknown>(url, body);
  }

  assignOpsAccount(
    accountId: number,
    opsAccountId: number
  ): Observable<{ modified: number }> {
    const url = `${this.basePath}/${accountId}/_assign`;

    return this.http.post<{ modified: number }>(url, {
      account_id: opsAccountId
    });
  }

  unassignOpsAccount(accountId: number): Observable<{ modified: number }> {
    const url = `${this.basePath}/${accountId}/_unassign`;

    return this.http.post<{ modified: number }>(url, {});
  }

  getAssignedCustomers(accountId: number): Observable<OpsAccountModel[]> {
    const url = `${this.basePath}/${accountId}/assigned-customers`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(map((json) => json.map((rec) => OpsAccountModel.fromJSON(rec))));
  }

  resendVerificationEmail(accountId: number): Observable<unknown> {
    const url = `${this.basePath}/${accountId}/_resend-verification-email`;

    return this.http.post<unknown>(url, {});
  }

  resetAgreement(accountId: number): Observable<unknown> {
    const url = `${this.basePath}/${accountId}/_reset-agreement`;

    return this.http.post<unknown>(url, {});
  }

  reset2FA(accountId: number): Observable<unknown> {
    const url = `${this.basePath}/token/app-reset/${accountId}`;

    return this.http.patch<unknown>(url, {});
  }

  enable(accountId: number): Observable<{ modified: number }> {
    const url = `${this.basePath}/${accountId}/_enable`;

    return this.http.post<{ modified: number }>(url, {});
  }

  close(
    accountId: number,
    body: AccountCloseReqPayload
  ): Observable<{ modified: number }> {
    const url = `${this.basePath}/${accountId}/_close`;

    return this.http.post<{ modified: number }>(url, body);
  }

  freeze(
    accountId: number,
    disableReason: string
  ): Observable<{ modified: number }> {
    const url = `${this.basePath}/${accountId}/_freeze`;

    const body = {
      disable_reason: disableReason
    };

    return this.http.post<{ modified: number }>(url, body);
  }

  updateCurrency(
    accountId: number,
    currency: Currency
  ): Observable<{ modified: number }> {
    const url = `${this.basePath}/${accountId}/currency`;

    return this.http.put<{ modified: number }>(url, { currency });
  }

  updateFlags(
    accountId: number,
    flags: AccountFlag[]
  ): Observable<{ modified: number }> {
    const url = `${this.basePath}/${accountId}/flags`;

    return this.http.put<{ modified: number }>(url, { flags });
  }

  updateAutomatedEmailsFlags(
    accountId: number,
    preferences: AccountPreference[]
  ): Observable<{ modified: number }> {
    const url = `${this.basePath}/${accountId}/preferences`;

    return this.http.put<{ modified: number }>(url, { preferences });
  }

  updateRequirements(
    accountId: number,
    requirements: AccountRequirement[]
  ): Observable<{ modified: number }> {
    const url = `${this.basePath}/${accountId}/requirements`;

    return this.http.put<{ modified: number }>(url, { requirements });
  }

  getOpsAccounts(): Observable<OpsAccountModel[]> {
    const url = `${this.basePath}`;

    const params = new HttpParams().set('internal', '1').set('status', 'any');

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(map((json) => json.map((rec) => OpsAccountModel.fromJSON(rec))));
  }

  createOpsAccount(
    data: OpsAccountCreateReqPayload
  ): Observable<{ id: number }> {
    const url = `${this.basePath}`;

    return this.http.post<{ id: number }>(url, data);
  }

  resetOpsAccountPassword(accountId: number): Observable<{ resp: boolean }> {
    const url = `${this.basePath}/internal/${accountId}/password/_reset`;

    return this.http.post<{ resp: boolean }>(url, {});
  }

  updateOpsAccountRole(
    accountId: number,
    role: AccountRoleEnum
  ): Observable<{ modified: number }> {
    const url = `${this.basePath}/internal/${accountId}/role`;
    const body = { role };

    return this.http.put<{ modified: number }>(url, body);
  }

  updateOpsAccountStatus(
    accountId: number,
    status: AccountStatus
  ): Observable<{ modified: number }> {
    let action;

    if (status === AccountStatus.Active) {
      action = '_suspend';
    } else if (status === AccountStatus.Disabled) {
      action = '_unsuspend';
    }

    const url = `${this.basePath}/internal/${accountId}/${action}`;

    return this.http.post<{ modified: number }>(url, {});
  }

  getFormData(): Observable<AccountFormDataModel> {
    const url = `${this.basePath}/form-data`;

    return this.http.get<AccountFormDataModel>(url);
  }

  checkEmailUniqueness(email: string): Observable<{ exists: boolean }> {
    const url = `${this.basePath}/email/_check`;

    return this.http.post<{ exists: boolean }>(url, { email });
  }

  checkUsernameUniqueness(username: string): Observable<{ exists: boolean }> {
    const url = `${this.basePath}/username/_check`;

    return this.http.post<{ exists: boolean }>(url, { username });
  }

  exportAccountPromoListAsFile(
    format = FileType.CSV
  ): Observable<HttpResponse<Blob>> {
    const url = `${this.basePath}/promo-list-post`;

    return this.fileDownloadService.exportToFile({
      url,
      format,
      filename: `accounts_promo_${new Date().toLocaleString()}.${format}`
    });
  }

  importAccountPromoList(
    format: 'csv' | 'json'
  ): Observable<OpsAccountModel[]> {
    const url = `${this.basePath}/promo-list-post`;

    let params = new HttpParams();

    if (format) {
      params = params.set('format', format);
    }

    return this.http
      .post<JsonArray>(url, { params })
      .pipe(map((json) => json.map((rec) => OpsAccountModel.fromJSON(rec))));
  }

  getByEmail(email: string): Observable<INewCustomerByEmail> {
    const url = `${this.basePath}/by-email`;

    const params = new HttpParams().set('email', email);

    return this.http
      .get<JsonObject>(url, { params })
      .pipe(map((json) => AccountCustomerByEmailModel.fromJSON(json)));
  }

  resetPendingEmail(accountId: number): Observable<{ modified: number }> {
    const url = `${this.basePath}/${accountId}/_reset-pending-email`;

    return this.http.post<{ modified: number }>(url, {});
  }

  verify(accountId: number, hash: string): Observable<IAccountVerify> {
    const url = `${this.basePath}/${accountId}/_verify`;

    const params = new HttpParams()
      .set('account_id', accountId)
      .set('hash', hash);

    return this.http
      .post<JsonObject>(url, { params })
      .pipe(map((json) => AccountVerifyModel.fromJSON(json)));
  }

  verifyEmail(accountId: number, hash: string): Observable<IAccountVerify> {
    const url = `${this.basePath}/${accountId}/_verify-email`;

    const params = new HttpParams()
      .set('account_id', accountId)
      .set('hash', hash);

    return this.http
      .post<JsonObject>(url, { params })
      .pipe(map((json) => AccountVerifyEmailModel.fromJSON(json)));
  }

  createContact(data: AccountCreateContactReqPayload): Observable<unknown> {
    const url = `${this.basePath}/contact`;
    const body = { data };

    return this.http.post<unknown>(url, body);
  }

  createFeedback(
    accountId: number,
    data: AccountCreateFeedbackReqPayload
  ): Observable<{ insertedId: number }> {
    const url = `${this.basePath}/${accountId}/feedback`;
    const body = { data };

    return this.http.post<{ insertedId: number }>(url, body);
  }

  getFeedback(
    accountId: number,
    featureId: number
  ): Observable<AccountFeedbackResPayload> {
    const url = `${this.basePath}/${accountId}/feedback`;

    const params = new HttpParams().set('featureId', featureId);

    return this.http.get<AccountFeedbackResPayload>(url, { params });
  }

  getFollowers(): Observable<FollowedAccountModel[]> {
    const url = `${this.basePath}/followers`;

    return this.http
      .get<IAccountFollowerResModel[]>(url, {})
      .pipe(
        map((json) => json.map((rec) => FollowedAccountModel.fromJSON(rec)))
      );
  }

  getFollowed(accountId: number): Observable<AccountFollowerModel[]> {
    const url = `${this.basePath}/followers/list/${accountId}`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(
        map((json) => json.map((rec) => AccountFollowerModel.fromJSON(rec)))
      );
  }

  checkFollowedStatus(accountId: number): Observable<{ is_followed: boolean }> {
    const url = `${this.basePath}/followers/check`;

    const params = new HttpParams().set('account_id', accountId);

    return this.http.get<{ is_followed: boolean }>(url, { params });
  }

  follow(body: AccountFollowReqPayload): Observable<{ id: number }> {
    const url = `${this.basePath}/followers/`;

    return this.http.post<{ id: number }>(url, body);
  }

  unfollow(accountId: number): Observable<{ result: boolean }> {
    const url = `${this.basePath}/followers/${accountId}`;

    return this.http.delete<{ result: boolean }>(url, {});
  }

  getNotifications(
    data: Partial<AccountNotificationReqPayload>
  ): Observable<{ totalCount: number; entities: AccountNotificationModel[] }> {
    const url = `${this.basePath}/followers/notifications`;

    const body = { account_id: data.accountId };

    const params = Object.entries(data).reduce((params, [key, value]) => {
      return typeof value === 'boolean' || value
        ? params.set(`${key}`, value)
        : params;
    }, new HttpParams());

    return this.http
      .post<JsonArray>(url, body, { observe: 'response', params })
      .pipe(
        map((json: HttpResponse<any[]>) => {
          const { headers, body } = json;
          const totalCount = parseInt(headers.get('x-total-count'));
          const entities = body.map((item) =>
            AccountNotificationModel.fromJSON(item)
          );

          return { totalCount, entities };
        })
      );
  }

  getUnseenNotificationsCount(): Observable<{ count: number }> {
    const url = `${this.basePath}/followers/notifications/unseen-count`;

    return this.http.get<{ count: number }>(url, {});
  }

  updateNotificationSeenStatus(
    notificationId: number
  ): Observable<{ isSeen: boolean }> {
    const url = `${this.basePath}/followers/notifications/${notificationId}`;

    return this.http
      .put<0 | 1>(url, {})
      .pipe(map((isSeen) => ({ isSeen: Boolean(isSeen) })));
  }

  resendFraud(
    data: Partial<{ accountId: number; email: string }>
  ): Observable<{ result: boolean }> {
    const url = `${this.basePath}/fraud/_resend`;

    const body: any = {};

    if (data.accountId) {
      body.account_id = data.accountId;
    }

    if (data.email) {
      body.email = data.email;
    }

    return this.http.post<{ result: boolean }>(url, body);
  }

  getNewCustomers(limit: number): Observable<INewCustomerByEmail> {
    const url = `${this.basePath}/newest-customers`;

    const params = new HttpParams().set('_limit', limit);

    return this.http
      .get<JsonObject>(url, { params })
      .pipe(map((json) => AccountNewCustomerModel.fromJSON(json)));
  }

  recoverPassword(
    data: AccountRecoverPasswordReqPayload
  ): Observable<{ modified: number }> {
    const url = `${this.basePath}/password/_recover`;
    const body = { data };

    return this.http.post<{ modified: number }>(url, body);
  }

  resetPassword(email: string): Observable<unknown> {
    const url = `${this.basePath}/password/_reset`;

    return this.http.post<unknown>(url, { email });
  }

  updatePassword(
    accountId: number,
    body: AccountUpdatePasswordReqPayload,
    token: string
  ): Observable<{ modified: number }> {
    const url = `${this.basePath}/${accountId}/password`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token
      })
    };

    return this.http.put<{ modified: number }>(url, body, httpOptions);
  }

  getPermissions(): Observable<Partial<IAccountPermission>> {
    const url = `${this.basePath}/permissions`;

    return this.http.get<JsonObject>(url, {});
  }

  getPermissionsById(accountId: number): Observable<{ permissions: string[] }> {
    const url = `${this.basePath}/${accountId}/permissions`;

    return this.http.get<{ permissions: string[] }>(url, {});
  }

  checkVerificationCode(
    data: AccountCheckVerificationCodeReqPayload
  ): Observable<{ is_valid: boolean }> {
    const url = `${this.basePath}/token/verification-code/check`;
    const body = { data };

    return this.http.post<{ is_valid: boolean }>(url, body);
  }

  getTokenList(
    accountId: number,
    dId: string
  ): Observable<AccountTokeListModel> {
    const url = `${this.basePath}/token/list`;
    const body = {
      account_id: accountId,
      d_id: dId
    };

    return this.http
      .post<JsonObject>(url, body)
      .pipe(map((json) => AccountTokeListModel.fromJSON(json)));
  }

  resendVerificationCode(accountId: number, jwt: string): Observable<any> {
    const url = `${this.basePath}/token/verification-code/resend`;

    const body = {
      account_id: accountId,
      jwt
    };

    return this.http.post<any>(url, body);
  }

  checkAntiPhishingCode(accountId: number): Observable<boolean> {
    const url = `${this.basePath}/${accountId}/anti-phishing-code/existing`;

    return this.http
      .get<{ anti_phishing_exist: boolean }>(url)
      .pipe(map(({ anti_phishing_exist }) => anti_phishing_exist));
  }

  resetAntiPhishingCode(accountId: number): Observable<unknown> {
    const url = `${this.basePath}/${accountId}/anti-phishing-code`;

    return this.http.delete(url);
  }
}
